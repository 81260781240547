/*
 * Coveo theme, overrides default that can be found here:
 * https://github.com/coveo/ui-kit/blob/master/packages/atomic/src/themes/coveo.css
 */
:root {
  /* Primary colors */
  --atomic-primary: #3a5bc7;
  --atomic-primary-light: #a6d5fa;
  --atomic-primary-dark: #4268e6;
  --atomic-on-primary: #ffffff;
  --atomic-ring-primary: #0a5cf5;

  /* Neutral colors */
  --atomic-neutral-dark: #626971;
  --atomic-neutral: #e5e8e8;
  --atomic-neutral-light: #f6f7f9;
  --atomic-neutral-lighter: #f2f2f2;

  /* Custom colors - matching tailwind theme values */
  --atomic-custom-white: #ffffff;
  --atomic-custom-black: #000000;
  --atomic-custom-grey-100: #f5f5f5;
  --atomic-custom-grey-200: #e6e6e6;
  --atomic-custom-grey-300: #cccccc;
  --atomic-custom-grey-400: #a6a6a6;
  --atomic-custom-grey-500: #808080;
  --atomic-custom-grey-600: #666666;
  --atomic-custom-grey-700: #4c4c4c;
  --atomic-custom-grey-800: #262626;
  --atomic-custom-grey-900: #141414;

  --atomic-custom-slate-200: #cbd5ea;
  --atomic-custom-slate-500: #717d96;

  /* Semantic colors */
  --atomic-background: #ffffff;
  --atomic-on-background: #000000;
  --atomic-success: #12a244;
  --atomic-error: #ce3f00;
  --atomic-visited: #3a5bc7;
  --atomic-disabled: #c5cacf;
  --atomic-success-background: #d4fcf0;
  --atomic-error-background: #fcbdc0;
  --atomic-primary-background: #edf6ff;

  /* Border radius */
  --atomic-border-radius: 0.25rem;
  --atomic-border-radius-md: 0.5rem;
  --atomic-border-radius-lg: 0.75rem;
  --atomic-border-radius-xl: 1rem;
  --atomic-border-radius-full: 9999px;

  /* Font, font-family overriden below */
  --atomic-font-normal: 400;
  --atomic-font-bold: 700;

  /* Text size */
  --atomic-text-base: 0.875rem; /* 14px */
  --atomic-text-sm: 0.75rem; /* 12px */
  --atomic-text-lg: 1rem; /* 16px */
  --atomic-text-xl: 1.125rem; /* 18px */
  --atomic-text-2xl: 1.5rem; /* 24px */
  --atomic-line-height-ratio: 1.5;

  /* Font weights */
  --atomic-custom-font-semibold: 600;

  /* Text tracking/spacing */
  --atomic-custom-tracking-wide: 0.18em;

  /* Layout */
  --atomic-layout-spacing-x: 1rem;
  --atomic-layout-spacing-y: 1rem;

  /* Custom spacing - matching tailwind theme values */
  --atomic-custom-spacing-1: 0.25rem;
  --atomic-custom-spacing-2: 0.5rem;
  --atomic-custom-spacing-4: 1rem;
  --atomic-custom-spacing-6: 1.5rem;
  --atomic-custom-spacing-8: 2rem;
  --atomic-custom-spacing-10: 2.5rem;
}

@media (min-width: 768px) {
  :root {
    --atomic-layout-spacing-x: 2rem;
  }
}

/* the --font-inter variable wasn't defined when overriding this within the :root block, but it works if we override it on an element itself */
atomic-search-interface,
atomic-external {
  --atomic-font-family: var(--font-inter, Helvetica, sans-serif), ui-sans-serif,
    system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; /* Copied from Tailwind generated font-sans font-family*/
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_900305';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://storage.googleapis.com/unity-mkt-services-prd-ui-static/marketplace/3fa1bb351b7ebe0188f24e2391ab934731272621/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_900305';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://storage.googleapis.com/unity-mkt-services-prd-ui-static/marketplace/3fa1bb351b7ebe0188f24e2391ab934731272621/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_900305';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://storage.googleapis.com/unity-mkt-services-prd-ui-static/marketplace/3fa1bb351b7ebe0188f24e2391ab934731272621/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_900305';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://storage.googleapis.com/unity-mkt-services-prd-ui-static/marketplace/3fa1bb351b7ebe0188f24e2391ab934731272621/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_900305';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://storage.googleapis.com/unity-mkt-services-prd-ui-static/marketplace/3fa1bb351b7ebe0188f24e2391ab934731272621/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_900305';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://storage.googleapis.com/unity-mkt-services-prd-ui-static/marketplace/3fa1bb351b7ebe0188f24e2391ab934731272621/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_900305';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://storage.googleapis.com/unity-mkt-services-prd-ui-static/marketplace/3fa1bb351b7ebe0188f24e2391ab934731272621/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_900305';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_900305 {font-family: '__Inter_900305', '__Inter_Fallback_900305';font-style: normal
}.__variable_900305 {--font-inter: '__Inter_900305', '__Inter_Fallback_900305'
}

