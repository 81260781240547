/*
 * Coveo theme, overrides default that can be found here:
 * https://github.com/coveo/ui-kit/blob/master/packages/atomic/src/themes/coveo.css
 */
:root {
  /* Primary colors */
  --atomic-primary: #3a5bc7;
  --atomic-primary-light: #a6d5fa;
  --atomic-primary-dark: #4268e6;
  --atomic-on-primary: #ffffff;
  --atomic-ring-primary: #0a5cf5;

  /* Neutral colors */
  --atomic-neutral-dark: #626971;
  --atomic-neutral: #e5e8e8;
  --atomic-neutral-light: #f6f7f9;
  --atomic-neutral-lighter: #f2f2f2;

  /* Custom colors - matching tailwind theme values */
  --atomic-custom-white: #ffffff;
  --atomic-custom-black: #000000;
  --atomic-custom-grey-100: #f5f5f5;
  --atomic-custom-grey-200: #e6e6e6;
  --atomic-custom-grey-300: #cccccc;
  --atomic-custom-grey-400: #a6a6a6;
  --atomic-custom-grey-500: #808080;
  --atomic-custom-grey-600: #666666;
  --atomic-custom-grey-700: #4c4c4c;
  --atomic-custom-grey-800: #262626;
  --atomic-custom-grey-900: #141414;

  --atomic-custom-slate-200: #cbd5ea;
  --atomic-custom-slate-500: #717d96;

  /* Semantic colors */
  --atomic-background: #ffffff;
  --atomic-on-background: #000000;
  --atomic-success: #12a244;
  --atomic-error: #ce3f00;
  --atomic-visited: #3a5bc7;
  --atomic-disabled: #c5cacf;
  --atomic-success-background: #d4fcf0;
  --atomic-error-background: #fcbdc0;
  --atomic-primary-background: #edf6ff;

  /* Border radius */
  --atomic-border-radius: 0.25rem;
  --atomic-border-radius-md: 0.5rem;
  --atomic-border-radius-lg: 0.75rem;
  --atomic-border-radius-xl: 1rem;
  --atomic-border-radius-full: 9999px;

  /* Font, font-family overriden below */
  --atomic-font-normal: 400;
  --atomic-font-bold: 700;

  /* Text size */
  --atomic-text-base: 0.875rem; /* 14px */
  --atomic-text-sm: 0.75rem; /* 12px */
  --atomic-text-lg: 1rem; /* 16px */
  --atomic-text-xl: 1.125rem; /* 18px */
  --atomic-text-2xl: 1.5rem; /* 24px */
  --atomic-line-height-ratio: 1.5;

  /* Font weights */
  --atomic-custom-font-semibold: 600;

  /* Text tracking/spacing */
  --atomic-custom-tracking-wide: 0.18em;

  /* Layout */
  --atomic-layout-spacing-x: 1rem;
  --atomic-layout-spacing-y: 1rem;

  /* Custom spacing - matching tailwind theme values */
  --atomic-custom-spacing-1: 0.25rem;
  --atomic-custom-spacing-2: 0.5rem;
  --atomic-custom-spacing-4: 1rem;
  --atomic-custom-spacing-6: 1.5rem;
  --atomic-custom-spacing-8: 2rem;
  --atomic-custom-spacing-10: 2.5rem;
}

@media (min-width: 768px) {
  :root {
    --atomic-layout-spacing-x: 2rem;
  }
}

/* the --font-inter variable wasn't defined when overriding this within the :root block, but it works if we override it on an element itself */
atomic-search-interface,
atomic-external {
  --atomic-font-family: var(--font-inter, Helvetica, sans-serif), ui-sans-serif,
    system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; /* Copied from Tailwind generated font-sans font-family*/
}
